@import '../../styles/common.scss';

.container {
  display: flex;
  position: relative;
  flex-direction: column;

  > .viewFinder {
    background-color: black;
  }
  > .bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;

    > .fieldsWrapper {
      width: 100%;
      max-width: 320px;

      > .fieldWrapper {
        display: flex;
        flex-direction: column;

        > label {
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          font-size: 14px;
        }
        > .field {
          margin-top: 4px;
          height: 48px;
          padding-left: 8px;
          font-size: 18px;
        }
      }
    }
    > .message {
      margin-top: 12px;
      font-size: 14px;
      color: red;
    }
    > .button {
      margin-top: 32px;
    }
  }
}
