@import '../styles/common.scss';

.button {
  font-size: 13px;
  height: 28px;
  padding: 0 16px 2px;
  display: flex;
  align-items: center;
  border: 1px solid $tint-color;
  border-radius: 4px;
  cursor: pointer;

  &.filled {
    background-color: $tint-color;
    color: white;

    &:hover {
      background-color: lighten($tint-color, 5%);
    }
  }
  &.bordered {
    background-color: white;
    color: $tint-color;

    &:hover {
      background-color: darken(white, 2%);
    }
  }
}
