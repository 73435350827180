@import '../../../styles/common.scss';

.container {
  .title {
    color: white;
    font-size: 16px;
  }
  > .form {
    display: flex;
    flex-direction: row;
    padding: 16px 20px;
    border-bottom: 1px solid $line-color;

    > .tableControl {
      flex: 1;
      display: flex;
      align-items: center;

      > .selectWrapper {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;

        > .select {
          text-align: center;
          font-size: 14px;
          width: 100%;
          height: 32px;
          padding: 0 4px;
        }
        > .arrow {
          margin: 8px 0;
        }
      }
      > .button {
        display: flex;
        align-items: center;
        border: 1px solid $border-color;
        border-radius: 2px;
        height: 100%;
        font-size: 14px;
        padding: 0 12px;
        cursor: pointer;

        &:not(:first-child) {
          margin-left: 8px;
        }
      }
    }
  }
  > .empty {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 120px;
    font-size: 16px;
    color: gray;
  }
  > .row {
    padding: 16px 20px;
    border-bottom: 1px solid $line-color;

    > .labels {
      display: flex;
      align-items: center;

      > .index {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background-color: $tint-color;
        color: white;
      }
      > .type {
        display: flex;
        align-items: center;
        height: 28px;
        padding: 0 8px 2px;
        margin-left: 8px;
        font-size: 13px;
        border-radius: 4px;
        color: $tint-color;
        border: 1px solid $tint-color;
      }
    }
    > .meta {
      margin-top: 8px;
      margin-left: 32px;
      display: flex;

      > .date {
        font-size: 14px;
        color: gray;
      }
      > .accent {
        margin-left: 8px;
        font-size: 14px;
        font-weight: bold;
        color: $tint-color;
      }
    }
    > .customer {
      display: flex;
      align-items: center;
      margin-top: 4px;
      margin-left: 32px;
      font-size: 14px;
    }
  }
}
