@import '../../styles/common.scss';

.container {
  display: flex;
  flex-direction: column;

  > .sectionHeader {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding-left: 20px;
    height: 64px;
    border-bottom: 1px solid $line-color;

    > .title {
      flex: 1;
      font-size: 18px;
      font-weight: bold;
      padding-bottom: 12px;
    }
    > .more {
      font-size: 14px;
      color: gray;
      padding: 12px 20px;
    }
  }
  > .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 20px;
    height: 56px;
    border-bottom: 1px solid $line-color;

    > .title {
      flex: 1;
      font-size: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    > .date {
      margin-left: 8px;
      font-size: 14px;
      color: gray;
    }
  }
}
