@use 'sass:color';

$tint-color: #015eb8;
$dark-color: #1a1a1a;
$line-color: #cccccc;
$face-color: #f2f2f2;
$border-color: #999999;

.hidden {
  display: none;
}
.monospace {
  font-family: 'Noto Sans Mono', monospace;
}
.w120 {
  width: 120px;
}
.w200 {
  width: 200px;
}
.w240 {
  width: 240px;
}
.w280 {
  width: 280px;
}
.w320 {
  width: 320px;
}
.w400 {
  width: 400px;
}
.wFull {
  width: 100%;
  margin-right: 8px;
}
.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
