@import '../../../styles/common.scss';

.container {
  display: flex;
  flex-direction: column;

  > .viewFinder {
    background-color: black;
  }
  > .title {
    font-size: 14px;
    padding-top: 16px;
    padding-bottom: 8px;
    padding-left: 20px;
    border-bottom: 1px solid $line-color;
  }
  > .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 20px;
    height: 40px;
    border-bottom: 1px solid $line-color;

    > .qrcode {
      margin-left: 8px;
      font-size: 16px;
    }
  }
}
