@import '../../../styles/common.scss';

.container {
  > .infoWrapper {
    display: flex;
    flex-direction: column;
    padding: 24px 20px 16px;

    > .rows {
      margin-top: 16px;
      border-top: 1px solid $line-color;

      > .row {
        display: flex;
        flex-direction: row;
        align-items: center;
        border-bottom: 1px solid $line-color;
        padding: 12px 0;

        > .label {
          font-size: 16px;
        }
        > .value {
          flex: 1;
          font-size: 16px;
          text-align: right;
        }
      }
    }
    > .infoBox {
      margin-top: 20px;
      padding: 20px 16px;
      border-radius: 4px;
      background-color: $tint-color;

      > .item {
        display: flex;
        flex-direction: column;
        align-items: center;

        &:not(:first-child) {
          margin-top: 20px;
          padding-top: 20px;
          border-top: 1px dashed rgba(white, 0.5);
        }
        > .label {
          font-size: 14px;
          color: white;
        }
        > .value {
          font-size: 20px;
          font-weight: bold;
          color: white;
          text-align: center;
          word-break: keep-all;
        }
      }
    }
  }
}
