@import '../../../styles/common.scss';

.container {
  display: flex;
  flex-direction: column;
  padding: 20px;

  > .title {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
  > .rows {
    margin-top: 20px;
    border-top: 1px solid $line-color;

    > .row {
      display: flex;
      flex-direction: row;
      align-items: center;
      border-bottom: 1px solid $line-color;
      padding: 12px 0;

      > .label {
        font-size: 16px;
      }
      > .value {
        flex: 1;
        font-size: 16px;
        text-align: right;
      }
    }
  }
  > .message {
    margin-top: 12px;
    font-size: 14px;
    color: red;
    text-align: center;
  }
  > .button {
    margin-top: 32px;
    align-self: center;
  }
}
