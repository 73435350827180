@import './common.scss';

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Noto Sans KR', sans-serif;
}
input,
select,
textarea {
  border: 1px solid $border-color;
  border-radius: 2px;
  color: black;
  background-color: white;

  &:focus {
    outline-color: $tint-color;
    background-color: lighten($tint-color, 55%);
  }
}
