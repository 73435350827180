@import '../../../styles/common.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;

  > .fieldsWrapper {
    margin: 0 auto;
    width: 100%;
    max-width: 320px;
    padding: 20px;

    > .fieldWrapper {
      display: flex;
      flex-direction: column;

      &:not(:first-child) {
        margin-top: 16px;
      }
      > label {
        font-size: 14px;

        > .required {
          color: red;
        }
      }
      > .select,
      > .field {
        margin-top: 4px;
        height: 48px;
        padding-left: 8px;
        font-size: 18px;

        &.textarea {
          padding: 8px;
          resize: none;
        }
      }
    }
  }
  > .message {
    margin-top: 12px;
    font-size: 14px;
    color: red;
  }
  > .actions {
    display: flex;
    padding: 20px;
    width: 320px;

    > .button {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      height: 56px;
      border-radius: 4px;
      font-size: 18px;
      color: white;

      &:not(:first-child) {
        margin-left: 8px;
      }
      &.negative {
        background-color: red;
      }
      &.primary {
        background-color: $tint-color;
      }
    }
  }
}
