@import '../../../styles/common.scss';

.container {
  align-self: center;
  width: 100%;
  max-width: 256px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  > .step {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
    border-radius: 4px;
    color: gray;
    border: 1px dashed $line-color;

    &.done {
      border-width: 0;
      background-color: $line-color;
    }
    &.active {
      color: $tint-color;
      border-width: 2px;
      border-style: solid;
      border-color: $tint-color;
    }
    > .title {
      margin-top: 4px;
      font-size: 13px;
    }
  }
  > .stepLine {
    width: 16px;
    height: 0;
    border-top: 1px dashed $line-color;
  }
}
