@import '../../../styles/common.scss';

.container {
  display: flex;
  flex-direction: column;
  padding: 20px;

  > .title {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
  > .button {
    margin-top: 32px;
    align-self: center;
  }
}
