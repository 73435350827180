@import '../../styles/common.scss';

.container {
  display: flex;
  align-items: center !important;
  justify-content: center;
  flex-direction: column;

  > .logo {
    margin-top: 24px;
    width: calc(502px / 2);
    height: calc(68px / 2);
    background-image: url('../../assets/images/logo.png');
    background-size: cover;
  }
  > .fieldsWrapper {
    margin-top: 56px;
    width: 100%;
    max-width: 320px;

    > .fieldWrapper {
      display: flex;
      flex-direction: column;

      &:not(:first-child) {
        margin-top: 12px;
      }
      > label {
        font-size: 14px;
      }
      > .field {
        margin-top: 4px;
        height: 48px;
        padding-left: 8px;
        font-size: 18px;
      }
    }
  }
  > .message {
    margin-top: 12px;
    font-size: 14px;
    color: red;
  }
  > .button {
    margin-top: 32px;
  }
}
