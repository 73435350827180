@import './common.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 20px 16px;

  > .header {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    font-size: 10px;
  }
  .answers {
    display: flex;
    flex-direction: row;
    align-items: center;

    > .answer {
      width: 28px;
      text-align: center;
    }
  }
  > .rows {
    position: relative;
    width: 100%;
    border-top: 1px solid $line-color;

    > .row {
      display: flex;
      align-items: center;
      border-bottom: 1px solid $line-color;
      height: 64px;

      &:nth-last-child(-n + 4) {
        float: right;
        width: 70%;
      }
      > .term {
        flex: 1;
        margin: 0 8px;
        font-size: 14px;
      }
    }
    > .tankImage {
      position: absolute;
      bottom: 0;
      left: 0;
      height: calc(64px * 3);
      width: 30%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url('../assets/images/checklist/tank.png');
    }
  }
  > .agreement {
    margin-top: 24px;
    font-size: 16px;
  }
  > .canvasWrapper {
    margin-top: 24px;
    position: relative;
    border: 1px solid $line-color;

    > .blurText {
      position: absolute;
      left: 50%;
      top: 50%;
      font-size: 64px;
      color: #dddddd;
      transform: translate(-50%, -50%);
      z-index: -10;
    }
    .canvas {
      width: 100%;
      height: 240px;
    }
  }
  > .message {
    margin-top: 12px;
    font-size: 14px;
    color: red;
  }
  > .button {
    margin-top: 32px;
  }
}
