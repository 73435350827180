@import '../styles/common.scss';

.container {
  height: 120px;
  font-size: 16px;
  color: gray;
  display: flex;
  align-items: center;
  justify-content: center;
}
