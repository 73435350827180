@import '../styles/common.scss';

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: lighten($dark-color, 3%);
  padding: 0 16px;
  height: 52px;

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;

    > *:not(:first-child) {
      margin-left: 8px;
    }
  }
}
