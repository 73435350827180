@import '../../../styles/common.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;

  > .fieldsWrapper {
    width: 100%;
    max-width: 320px;

    > .fieldWrapper {
      display: flex;
      flex-direction: column;

      &:not(:first-child) {
        margin-top: 12px;
      }
      > label {
        font-size: 14px;
      }
      > .field {
        margin-top: 4px;
        height: 48px;
        padding-left: 8px;
        font-size: 18px;
      }
    }
  }
  > .message {
    margin-top: 12px;
    font-size: 14px;
    color: red;
  }
  > .button {
    margin-top: 32px;
  }
}
