@import '../styles/common.scss';

.container {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  background-color: lighten($dark-color, 10%);

  > h1 {
    font-size: 18px;
    color: white;
  }
}
