@import '../styles/common.scss';

.container {
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: lighten($dark-color, 10%);
  z-index: 10;

  > .wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding: 0 8px;
    height: 64px;

    > .button {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: white;

      &.active {
        color: lighten($tint-color, 20%);
        font-weight: bold;
      }
      > .name {
        font-size: 12px;
      }
    }
  }
}
