@import '../../styles/common.scss';

.container {
  display: flex;
  flex-direction: column;

  .total {
    color: white;
    font-size: 14px;
  }
  .row {
    padding: 16px 20px;
    border-bottom: 1px solid $line-color;

    > .labels {
      display: flex;
      align-items: center;
      justify-content: space-between;

      > .status {
        display: flex;
        align-items: center;
        height: 28px;
        padding: 0 8px 2px;
        font-size: 13px;
        border-radius: 4px;
        color: $tint-color;
        border: 1px solid $tint-color;
      }
      > .orderNo {
        font-size: 13px;
        color: $tint-color;
      }
    }
    > .meta {
      margin-top: 8px;
      display: flex;

      > .date {
        font-size: 14px;
        color: gray;
      }
      > .accent {
        margin-left: 8px;
        font-size: 14px;
        font-weight: bold;
        color: $tint-color;
      }
    }
    > .customer {
      margin-top: 4px;
      font-size: 14px;
    }
    > .address {
      font-size: 14px;
    }
  }
}
