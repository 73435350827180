@import '../../../styles/common.scss';

.container {
  display: flex;
  flex-direction: column;

  .total {
    font-size: 14px;
    color: white;
  }
  select {
    font-size: 14px;
    height: 28px;
    padding: 0 4px;
    border: 1px solid $border-color;
    border-radius: 2px;
  }
  > .row {
    display: flex;
    align-items: center;
    height: 80px;
    padding: 0 24px;
    border-bottom: 1px solid $line-color;

    > .role {
      display: flex;
      align-items: center;
      height: 28px;
      padding: 0 8px 2px;
      font-size: 13px;
      border-radius: 4px;
      color: $tint-color;
      border: 1px solid $tint-color;
    }
    > .user {
      flex: 1;
      margin-left: 24px;

      > .name {
        font-size: 14px;
        font-weight: bold;
        color: $tint-color;
      }
      > .phone {
        font-size: 14px;
      }
    }
  }
  > .button {
    position: fixed;
    right: 16px;
    bottom: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    background-color: $tint-color;
    border-radius: 50%;
    cursor: pointer;
  }
}
