@import '../styles/common.scss';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 320px;
  height: 56px;
  border-radius: 4px;
  font-size: 18px;
  background-color: $tint-color;
  color: white;
}
