@import '../../styles/common.scss';

.container {
  display: flex;
  flex-direction: column;

  > .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 20px;
    height: 64px;
    border-bottom: 1px solid $line-color;

    > .title {
      font-size: 16px;
      margin-left: 8px;
    }
  }
}
