@import '../../styles/common.scss';

.container {
  display: flex;
  flex-direction: column;
  padding: 20px;

  > .title {
    font-size: 18px;
    font-weight: bold;
  }
  > .date {
    margin-top: 8px;
    font-size: 14px;
    color: gray;
  }
  > .body {
    margin-top: 24px;
    font-size: 16px;
  }
}
