@import '../../../styles/common.scss';

.container {
  .title {
    color: white;
    font-size: 16px;
  }
  > .empty {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 120px;
    font-size: 16px;
    color: gray;
  }
  > .header {
    display: flex;
    align-items: center;
    height: 32px;
    background-color: $face-color;

    > .column {
      font-weight: bold;
      font-size: 12px;
    }
  }
  > .row {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $line-color;
    height: 56px;

    > .column {
      font-size: 16px;
    }
  }
  .column {
    display: flex;
    justify-content: center;
    align-items: center;

    &.info {
      flex: 1;
    }
    &.deletion {
      width: 64px;
    }
  }
}
